import React, { useState, useEffect } from "react";
import "./Gallery_page.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Cover from "../gallery_pics/cover-photo.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

// Function to import all images from gallery_pics folder
function importAll(r) {
  return r.keys().map(r);
}

// Import all images from gallery_pics folder
const images = importAll(
  require.context("../gallery_pics", false, /\.(png|jpe?g|svg)$/)
);

const GalleryPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imagesPerPage, setImagesPerPage] = useState(15);

  useEffect(() => {
    const updateImagesPerPage = () => {
      if (window.innerWidth <= 768) {
        setImagesPerPage(8);
      } else {
        setImagesPerPage(15);
      }
    };

    updateImagesPerPage(); // Initial check

    window.addEventListener('resize', updateImagesPerPage);
    return () => window.removeEventListener('resize', updateImagesPerPage);
  }, []);

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  const totalPages = Math.ceil(images.length / imagesPerPage);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      window.scrollTo({ top: 300, behavior: 'smooth' });
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      window.scrollTo({ top: 300, behavior: 'smooth' });
    }
  };

  const handleNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handlePreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  return (
    <div>
      <Header />
      <div
        className="gallery-header"
        style={{
          backgroundImage: `url(${Cover})`,
        }}
      >
        <h1>Gallery</h1>
      </div>
      <div className="photos">
        {currentImages.map((image, index) => (
          <div key={index} className="photo-container">
            <img
              src={image}
              alt=""
              onClick={() => openModal(indexOfFirstImage + index)}
            />
          </div>
        ))}
      </div>
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
      <Modal
        className="custom-modal"
        show={modalIsOpen}
        onHide={closeModal}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="modal-content">
          <Button
            onClick={handlePreviousImage}
            disabled={currentImageIndex === 0}
            className="nav-button prev-button"
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} />
          </Button>
          <img
            src={images[currentImageIndex]}
            alt=""
            style={{ width: "100%" }}
          />
          <Button
            onClick={handleNextImage}
            disabled={currentImageIndex === images.length - 1}
            className="nav-button next-button"
          >
            <FontAwesomeIcon icon={faArrowCircleRight} />
          </Button>
        </div>
      </Modal>
      <Footer />
    </div>
  );
};

export default GalleryPage;
