import React from "react";
import "./Gallery.css";
import { Button } from 'react-bootstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Carousel } from 'react-responsive-carousel';

const Gallery = () => {
  return (
    <div className="gallery" id="gallery">
      <h3 className="section-subtitle3">GALLERY</h3>
      <h1>Our Precious <span className="moments">Moments</span> <i className="fas fa-camera"></i></h1>
      <div className="images desktop-view">
        <img
          src={require("../gallery_pics/gallerypic1.jpg")}
          className="img-thumbnail"
          alt="Book Event"
        />
        <img
          src={require("../gallery_pics/gallerypic2.jpg")}
          className="img-thumbnail"
          alt="Tree Plantation"
        />
        <img
          src={require("../gallery_pics/gallerypic3.jpg")}
          className="img-thumbnail"
          alt="Certificate Event"
        />
        <img
          src={require("../gallery_pics/gallerypic4.jpg")}
          className="img-thumbnail"
          alt="Charity Event"
        />
      </div>
      <div className="carousel-container mobile-view">
        <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true} interval={3000}>
          <div>
            <img className="image-gallery" src={require("../gallery_pics/gallerypic1.jpg")} alt="Book Event" />
          </div>
          <div>
            <img className="image-gallery" src={require("../gallery_pics/gallerypic2.jpg")} alt="Tree Plantation" />
          </div>
          <div>
            <img className="image-gallery" src={require("../gallery_pics/gallerypic3.jpg")} alt="Certificate Event" />
          </div>
          <div>
            <img className="image-gallery" src={require("../gallery_pics/gallerypic4.jpg")} alt="Charity Event" />
          </div>
        </Carousel>
      </div>
      <div className="view-button">
        <Button href="/gallery" variant="warning" className="mr-2 view-btn">View More</Button>
      </div>
    </div>
  );
};

export default Gallery;
