import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Footer.css"; // Custom CSS

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <Row>
          <Col md={4} className="footer-about">
            <img
              src={require("../assets/jijamata-logo.jpeg")}
              alt="Logo"
              className="footer-logo"
            />
            <p>
              Jijamata Sansthan has been working in the fields of Social
              Education, Healthcare, Employment and Culture since 2008 under the
              guidance of Miss. Vaishali Sonawane.
            </p>
            <Button
              variant="warning"
              className="footer-donate-btn"
              href="/donate"
            >
              Donate Now
            </Button>
          </Col>
          <Col md={2} className="footer-links">
            <h5>Links</h5>
            <ul>
              <li>
                <a href="/">About Us</a>
              </li>
              <li>
                <a href="/">Join Us</a>
              </li>
              <li>
                <a href="/gallery">Gallery</a>
              </li>
              <li>
                <a href="/">Contact</a>
              </li>
              <li>
                <a href="/">Donation</a>
              </li>
            </ul>
          </Col>
          <Col md={2} className="footer-links">
            <h5>Non profit</h5>
            <ul>
              <li>
                <a href="/">Health Care</a>
              </li>
              <li>
                <a href="/">Education</a>
              </li>
              <li>
                <a href="/">
                  Amazing Shala
                </a>
              </li>
              <li>
                <a href="/">Give them Education</a>
              </li>
              <li>
                <a href="/">Start a Fundraising</a>
              </li>
            </ul>
          </Col>
          <Col md={4} className="footer-contact">
            <h5>Contact</h5>
            <p>
              Shree Niwas' thakkar compound old agra road near forest office
              shahapur, Pincode - 421601, Maharashtra, India
            </p>
            <p>
              <a href="mailto:jijamatasamajiksanstha@gmail.com">
                jijamatasamajiksanstha@gmail.com
              </a>
            </p>
            <p>+91 7038760047</p>
            <p>+91 9623322166</p>
            <div className="footer-social">
              <a href="https://www.youtube.com/@jijamatasamajiksanstha">
                <i className="fab fa-youtube"></i>
              </a>
              <a href="https://www.facebook.com/people/Jijamata-Samajik-Sanstha/100085397355018/?mibextid=qi2Omg&rdid=u78WjKuMz57yY1Vm&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FNVSq5gfwwX9vEeTu%2F%3Fmibextid%3Dqi2Omg">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://wa.me/7038760047">
                <i className="fab fa-whatsapp"></i>
              </a>
              <a href="https://www.instagram.com/jijamata_samajik_sanstha_?utm_source=qr">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </Col>
        </Row>
        <Row className="footer-bottom">
          <Col className="text-center">
            <p>
              © 2024, Jijamata Samajik Sanstha All Rights Reserved | Powered By{" "}
              <a href="https://webirstdecor.com">DABStar</a>
            </p>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
