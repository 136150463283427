import React from 'react';
import './JoinUs.css';

const JoinUs = () => {
  return (
    <div className="parallax-container">
      <div className='parallax-overlay'></div>
      <div className="parallax-content">
        <h3>GET INVOLVE</h3>
        <h1>We've Funded 50+ Charity Projects For 5k+ People Around The Region</h1>
        <button className="parallax-button" onClick={() => window.location.href='https://forms.gle/EFdn2k5XSBiRGwuN8'}>Get Involve Now</button>
      </div>
    </div>
  );
};

export default JoinUs;
