import React from "react";
import { Container, Button, Carousel } from "react-bootstrap";
import Carousel1 from "../assets/carousel1.jpg";
import Carousel2 from "../assets/carousel2.jpg";
import Carousel3 from "../assets/carousel3.jpg";
import "./Hero.css"; // Custom CSS

const Hero = () => {
  return (
    <div className="hero">
      <Carousel>
        <Carousel.Item>
          <div
            className="hero-slide"
            style={{ backgroundImage: `url(${Carousel1})` }}
          >
            <div className="hero-overlay"></div>
            <Container className="hero-container">
              <h1 className="hero-title">
                Let's Build The Better World Together
              </h1>
              <p className="hero-para">
              Be a part of a global movement striving for a brighter, more sustainable future. Your contribution can spark hope and transform lives.
              </p>
              <Button
                href="/donate"
                variant="warning"
                className="mr-2 donate-btn"
              >
                Donate fund
              </Button>
            </Container>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className="hero-slide"
            style={{ backgroundImage: `url(${Carousel2})` }}
          >
            <div className="hero-overlay"></div>
            <Container className="hero-container">
              <h1 className="hero-title">Empowering Communities for a Better Future</h1>
              <p className="hero-para">
                Join us in our mission to empower communities through
                sustainable projects. Together, we can make a difference.
              </p>
              <Button
                href="/donate"
                variant="warning"
                className="mr-2 donate-btn"
              >
                Donate fund
              </Button>
            </Container>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className="hero-slide"
            style={{ backgroundImage: `url(${Carousel3})` }}
          >
            <div className="hero-overlay"></div>
            <Container className="hero-container">
              <h1 className="hero-title">Creating Opportunities for a Brighter Tomorrow</h1>
              <p className="hero-para">
                Join us in our mission to empower communities through sustainable projects. Together, we can make a difference.
              </p>
              <Button
                href="/donate"
                variant="warning"
                className="mr-2 donate-btn"
              >
                Donate fund
              </Button>
            </Container>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Hero;
