import React, { useState, useRef } from "react";
import "./Donate.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import QRCode from '../assets/jijamata_qr.PNG'
import emailjs from "@emailjs/browser";

const Donate = () => {
  const [paymentMethod, setPaymentMethod] = useState("UPI");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_6fp25za', 'template_cmpsr09', form.current, 'RWe9ms55BGOQppeJT')
      .then(
        (result) => {
          console.log('SUCCESS!', result.text);
          alert("Donation submitted successfully!");
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert("An error occurred, please try again.");
        },
      );

    // Reset form
    e.target.reset();
  };

  return (
    <>
      <Header />
      <section className="donate-now-section">
        <div className="container">
          <h2 className="section-title">
            Give Now to Help a Student Realise Their Dream!
          </h2>
          <p className="section-subtitle2">
            Your donation can have a profound impact on a child's educational
            journey; helping them today means investing in our community's
            future rather than just providing money. Every rupee you provide
            goes towards helping kids who need the most to realise their Dream!
          </p>
          <form ref={form} onSubmit={sendEmail} className="donation-form">
            <div className="left-side">
              <h3 className="form-title">Personal Information</h3>
                <input className="form-group"
                  type="text"
                  name="from_name"
                  placeholder="Your Name"
                  required
                />
                <input className="form-group" type="tel" name="from_phone" placeholder="Phone" required />
                <input className="form-group"
                  type="email"
                  name="from_email"
                  placeholder="Email address"
                  required
                />
                <input className="form-group"
                  type="text"
                  name="from_address"
                  placeholder="Address"
                  required
                />
                <input className="form-group"
                  type="text"
                  name="from_amount"
                  placeholder="Amount Donated"
                  required
                />
                <textarea className="form-group"
                  name="message"
                  placeholder="Write message"
                  required
                ></textarea>
              <button type="submit" className="submit-button">
                Submit
              </button>
            </div>
            <div className="right-side">
              <div className="form-group">
              <label htmlFor="paymentMethod">Choose Payment Method</label>
                <select
                  id="paymentMethod"
                  name="paymentMethod"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <option value="UPI">UPI Payment</option>
                  <option value="Card">Card Payment</option>
                </select>
              </div>
              {paymentMethod === "UPI" ? (
                <div className="qr-code-section">
                  <img
                    src={QRCode}
                    alt="UPI QR Code"
                  />
                  <p>Scan the QR code to donate via UPI</p>
                </div>
              ) : (
                <div className="card-payment-section">
                  <input
                    type="text"
                    name="cardNumber"
                    placeholder="Card Number"
                    required
                  />
                  <input
                    type="text"
                    name="cardName"
                    placeholder="Name on Card"
                    required
                  />
                  <input
                    type="text"
                    name="cardExpiry"
                    placeholder="Expiry Date (MM/YY)"
                    required
                  />
                  <input
                    type="text"
                    name="cardCVC"
                    placeholder="CVC"
                    required
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Donate;
