import React from 'react';
import './Founder.css'; // Make sure to create this CSS file
import founderImage from '../assets/Ms_Vaishali.jpg'; // Replace with your actual image path

const Founder = () => {
  return (
    <section className="founder-section" id='founder'>
      <div className="container">
        <div className="founder-image">
          <img src={founderImage} alt="Ms Vaishali Sonawane" />
        </div>
        <div className="founder-info">
          <h4 className="section-subtitle">Jijamata Samajik Sansthan</h4>
          <h1 className="founder-name">Ms. Vaishali Sonawane</h1>
          <h3 className="founder-title">Founder & President</h3>
          <p className="founder-description">
          Ms. Vaishali Sonawane stands as a beacon of excellence in the dynamic realm of social empowerment and cultural preservation. With over 16 years of dedicated experience, she has carved a unique niche as the President and Founder of Jijamata Samajik Sansthan, an NGO based in Shahapur, Maharashtra. Her organization works tirelessly in the fields of culture, employment, and women empowerment, making a profound impact across the region.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Founder;
