import React from 'react'
import Header from "../components/Header.js";
import Hero from "../components/Hero.js";
import AboutUs from "../components/AboutUs.js";
import JoinUs from '../components/JoinUs.js';
import Gallery from '../components/Gallery.js';
import UpcomingEvents from '../components/UpcomingEvents.js';
import Footer from "../components/Footer.js";
import Founder from '../components/Founder.js';

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <AboutUs />
      <JoinUs />
      <UpcomingEvents />
      <Gallery />
      <Founder />
      <Footer />
    </div>
  )
}

export default Home